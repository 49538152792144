import * as React from "react";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DisplaySnackbar from "../../../../shared/Components/DisplaySnackbar";
import { useFormik } from "formik";
import { leaves } from "../../../../shared/HTTP/HttpCalls";
import { getUserId } from "../../../../shared/LocalStorage/LocalStorageCalls";
import { typeOfLeaves } from "../../../../shared/global-constants";
import {
  snackbarData,
  snackbarStart,
} from "../../../../shared/Components/DisplaySnackbar";
import { LeaveCreateSchema } from "../../../../shared/FormValidationSchema";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { calculateDaysBtwTwoDates } from "../../../../shared/utils";

const LeaveCreateDialog = (props) => {
  const [snackbarOpen, setSnackbarOpen] = React.useState(snackbarData);
  const [errorMsg, setErrorMsg] = React.useState("");

  const onSubmit = (formData, { resetForm }) => {
    formData["reason"] = formData["reason"].trim();
    leaves
      .create({
        ...formData,
        users_permissions_user: getUserId(),
      })
      .then((res) => {
        props.tableUpdater([res.data, ...props.rows]);
        setSnackbarOpen(
          snackbarStart("success", "Record Added", res.statusText, res.status)
        );
      })
      .then(() => {
        props.dialogClose();
        resetForm();
      })
      .catch((err) => {
        setSnackbarOpen(snackbarStart("error", err.toString()));
      });
    resetForm();
  };

  const formik = useFormik({
    initialValues: {
      type: "",
      halfday: false,
      reason: "",
      fromdate: "",
      todate: "",
    },
    onSubmit,
    validationSchema: LeaveCreateSchema,
  });

  const validateLeavesStock = (formDate, toDate) => {
    if (formDate !== "" && toDate !== "") {
      const totaldays = calculateDaysBtwTwoDates(formDate, toDate);
      if (
        totaldays > props.remainingLeaves[formik.values.type.concat("leaves")]
      ) {
        setErrorMsg(
          `You have only ${
            props.remainingLeaves[formik.values.type.concat("leaves")]
          } ${formik.values.type} leaves, you can't apply for ${totaldays} days`
        );
      } else {
        setErrorMsg("");
      }
    }
  };

  const handleToDate = (e) => {
    validateLeavesStock(formik.values.fromdate, e.target.value);
    formik.setFieldValue("todate", e.target.value);
  };
  const handleFromDate = (e) => {
    validateLeavesStock(e.target.value, formik.values.todate);
    formik.setFieldValue("fromdate", e.target.value);
  };
  const handleType = (e) => {
    formik.setFieldValue("type", e.target.value);
  };
  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => {
          props.dialogClose();
          formik.resetForm();
        }}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Create Leave Request
          <div id="icons" style={{ display: "flex", alignItems: "center" }}>
            <CloseIcon
              onClick={() => {
                props.dialogClose();
                formik.resetForm();
              }}
              sx={{ "&:hover": { cursor: "pointer" } }}
            />
          </div>
        </DialogTitle>

        <DialogContent>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 0 }}
            sx={{ mb: 2, display: "flex", alignItems: "center" }}
          >
            <TextField
              {...formik.getFieldProps("type")}
              {...(formik.touched.type &&
                formik.errors.type && { error: true })}
              helperText={(formik.touched.type && formik.errors.type) || " "}
              select
              label="Type Of Leave"
              variant="filled"
              sx={{ width: "50%" }}
              onChange={handleType}
            >
              {typeOfLeaves.map((type) => {
                if (props.remainingLeaves[type.concat("leaves")] > 0) {
                  return (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  );
                } else {
                  if (type === "unpaid") {
                    return (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    );
                  }
                  return (
                    <MenuItem disabled key={type} value={type}>
                      {type}
                    </MenuItem>
                  );
                }
              })}
            </TextField>
            <FormControlLabel
              {...formik.getFieldProps("halfday")}
              {...(formik.touched.halfday &&
                formik.errors.halfday && { error: true })}
              control={<Checkbox checked={formik.values.halfday} />}
              label="Half Day"
              name="halfday"
              sx={{ flex: "1", width: "50%" }}
            />
          </Stack>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 0 }}
            sx={{ mb: 2 }}
          >
            <TextField
              {...formik.getFieldProps("fromdate")}
              {...(formik.touched.fromdate &&
                formik.errors.fromdate && { error: true })}
              helperText={
                (formik.touched.fromdate && formik.errors.fromdate) || " "
              }
              type="date"
              label="From Date"
              variant="filled"
              sx={{ minWidth: "50%", maxWidth: "100%", padding: "7px" }}
              onChange={handleFromDate}
            ></TextField>

            <TextField
              {...formik.getFieldProps("todate")}
              {...(formik.touched.todate &&
                formik.errors.todate && { error: true })}
              helperText={
                (formik.touched.todate && formik.errors.todate) || " "
              }
              type="date"
              label="To Date"
              variant="filled"
              sx={{ minWidth: "50%", maxWidth: "100%", padding: "7px" }}
              onChange={handleToDate}
            ></TextField>
          </Stack>

          {errorMsg !== "" && (
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2 }}
            >
              <Typography variant="body2" color="error">
                {errorMsg}
              </Typography>
            </Stack>
          )}

          <TextField
            {...formik.getFieldProps("reason")}
            {...(formik.touched.reason &&
              formik.errors.reason && { error: true })}
            helperText={(formik.touched.reason && formik.errors.reason) || " "}
            label="Reason"
            minRows={3}
            multiline
            variant="filled"
            placeholder="Leave Reason"
            sx={{ width: "100%" }}
          />
        </DialogContent>

        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            onClick={formik.handleSubmit}
            disabled={!(formik.dirty && formik.isValid && errorMsg === "")}
          >
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>

      <DisplaySnackbar
        open={snackbarOpen.open}
        closeSnackbar={() => {
          setSnackbarOpen({ open: false, severity: "", message: "" });
        }}
        message={snackbarOpen.message}
        severity={snackbarOpen.severity}
        statusCode={snackbarOpen.statusCode}
      />
    </>
  );
};
export default LeaveCreateDialog;
