class LocalStorageService {

    constructor() {
        this.localStorage = window.localStorage;
    }

    get(key) {
        return this.localStorage.getItem(key);
    }

    set(key, item) {
        return this.localStorage.setItem(key, item);
    }

    remove(key) {
        return this.localStorage.removeItem(key);
    }

    clearStorage() {
        return this.localStorage.clear();
    }

};

export default LocalStorageService;