import axios from "axios";
import { apiURL } from "../global-constants";
import { getToken } from "../LocalStorage/LocalStorageCalls";

class Service {
  constructor() {
    this.authorizedAxios = getToken()
      ? axios.create({
          baseURL: apiURL,
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
      : axios.create({
          baseURL: apiURL,
        });

    this.authorizedAxios.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    this.authorizedAxios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );
  }

  // `${apiURL}${endpoint}?${query[param]}`

  joinURL(endpoint, id = "", query = "") {
    if (id === "") {
      let url = `${apiURL}${endpoint}?`;
      Object.keys(query).forEach((param) => {
        url = url.concat(`${param}=${query[param]}&`);
      });
      return url;
    } else {
      return `${apiURL}${endpoint}/${id}`;
    }
  }

  async get(endpoint, id, query) {
    let url = this.joinURL(endpoint, id, query);
    return await this.authorizedAxios.get(`${url}`).then((res) => {
      return res;
    });
  }

  async post(endpoint, data) {
    let url = this.joinURL(endpoint);
    return await this.authorizedAxios.post(`${url}`, data).then((res) => {
      return res;
    });
  }

  async put(endpoint, id, data) {
    let url = this.joinURL(endpoint, id);
    return await this.authorizedAxios.put(`${url}`, data).then((res) => {
      return res;
    });
  }

  async delete(endpoint, id) {
    let url = this.joinURL(endpoint, id);
    return await this.authorizedAxios.delete(`${url}`).then((res) => {
      return res;
    });
  }
}

export default Service;
