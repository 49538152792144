import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox } from '@mui/material';

export default function FormDialog({isDialogOpen,dialogHandler,signInHandler,ifSignedIn}) {
  const [checked, setChecked] = React.useState(false);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  React.useEffect(() => {
    setChecked(false)
  }, [isDialogOpen])

  return (
    <div>
      <Dialog open={isDialogOpen} onClose={()=> dialogHandler(false)}>
        <DialogTitle>Attendance</DialogTitle>
        <DialogContent sx={{textAlign:"center"}}>
          <DialogContentText>
            Please check the checkbox to { ifSignedIn ? "sign out" : "sign in" }
          </DialogContentText>
          <Checkbox {...label} 
            checked={checked}
            onChange={(e)=>setChecked(e.target.checked)}
          size="large" />
        </DialogContent>
        <DialogActions>
        <Button onClick={()=>signInHandler()}
        disabled={checked===false}
        >
          {
                ifSignedIn ?
                    "SIGN OUT"
                    :
                    "SIGN IN"
            }
        </Button>
          <Button onClick={()=>dialogHandler(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}