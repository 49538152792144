import * as React from "react";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DisplaySnackbar from "../../../../shared/Components/DisplaySnackbar";
import StatusDialog from "./StatusDialog";
import { useFormik } from "formik";
import { leaves } from "../../../../shared/HTTP/HttpCalls";
import { typeOfLeaves, leaveStatus } from "../../../../shared/global-constants";
import {
  snackbarData,
  snackbarStart,
} from "../../../../shared/Components/DisplaySnackbar";
import { LeaveUpdateSchema } from "../../../../shared/FormValidationSchema";

import DeleteText from "../../../../shared/Components/DeleteText";
import DeleteUpdatePermission from "../../../../shared/Components/DeleteUpdatePermission";
import { UserPermissions } from "../../../../shared/global-constants";
import { Checkbox, FormControlLabel } from "@mui/material";
import { calculateDaysBtwTwoDates } from "../../../../shared/utils";

const LeaveUpdateDialog = (props) => {
  const [disabled, setDisabled] = React.useState({ disabled: true });
  const [snackbarOpen, setSnackbarOpen] = React.useState(snackbarData);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [statusDialog, setStatusDialog] = React.useState({
    mode: "",
    open: false,
  });
  const [errorMsg, setErrorMsg] = React.useState("");

  const onSubmit = (formData) => {
    formData["reason"] = formData["reason"].trim();
    leaves
      .update(props.dialogData._id, formData)
      .then((res) => {
        props.tableUpdater(
          props.rows.map((obj) => (obj._id === res.data._id ? res.data : obj))
        );
        setSnackbarOpen(
          snackbarStart("success", "Record Updated", res.statusText, res.status)
        );
      })
      .then(() => {
        handleClose();
      })
      .catch((err) => {
        setSnackbarOpen(snackbarStart("error", err.toString()));
      });
  };

  const formik = useFormik({
    initialValues: {
      type: props.dialogData.type,
      status: props.dialogData.status,
      fromdate: props.dialogData.fromdate,
      todate: props.dialogData.todate,
      reason: props.dialogData.reason,
      rejectreason: props.dialogData.rejectreason,
      halfday: props.dialogData.halfday,
    },
    enableReinitialize: true,
    onSubmit,
    validationSchema: LeaveUpdateSchema,
  });

  const handleClose = () => {
    setStatusDialog({ open: false, mode: "" });
    setDeleteDialogOpen(false);
    props.dialogClose();
    formik.resetForm();
    setDisabled({ disabled: true });
  };

  const editHandler = () => {
    if (disabled["disabled"] === true) {
      setDisabled({ disabled: false });
    } else {
      setDisabled({ disabled: true });
      formik.resetForm();
    }
  };

  //send delete req and close dialog(s)
  const deleteHandler = () => {
    leaves
      .delete(props.dialogData._id)
      .then((res) => {
        props.tableUpdater(
          props.rows.filter((obj) => obj._id !== res.data._id)
        );
        setSnackbarOpen(
          snackbarStart("success", "Record Deleted", res.statusText, res.status)
        );
      })
      .catch((err) => {
        setSnackbarOpen(snackbarStart("error", err.toString()));
      });
    handleClose();
  };

  const validateLeavesStock = (formDate, toDate) => {
    if (formDate !== "" && toDate !== "") {
      const totaldays = calculateDaysBtwTwoDates(formDate, toDate);
      if (
        totaldays > props.remainingLeaves[formik.values.type.concat("leaves")]
      ) {
        setErrorMsg(
          `You have only ${
            props.remainingLeaves[formik.values.type.concat("leaves")]
          } ${formik.values.type} leaves, you can't apply for ${totaldays} days`
        );
      } else {
        setErrorMsg("");
      }
    }
  };

  const handleToDate = (e) => {
    validateLeavesStock(formik.values.fromdate, e.target.value);
    formik.setFieldValue("todate", e.target.value);
  };
  const handleFromDate = (e) => {
    validateLeavesStock(e.target.value, formik.values.todate);
    formik.setFieldValue("fromdate", e.target.value);
  };
  const handleType = (e) => {
    formik.setFieldValue("type", e.target.value);
  };

  return (
    <>
      {/*below is update dialog*/}
      <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Update {props.dialogData.username}'s Req.
          <div
            id="icons"
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "nowrap",
              justifyContent: "space-between",
            }}
          >
            <DeleteIcon
              onClick={() => {
                setDeleteDialogOpen(true);
                setDisabled({ disabled: true });
                formik.resetForm();
              }}
              sx={{ margin: "0px 5px", cursor: "pointer" }}
            />
            <EditIcon
              onClick={() => {
                editHandler();
              }}
              sx={{ margin: "0px 5px", cursor: "pointer" }}
            />
            <CloseIcon
              onClick={handleClose}
              sx={{ margin: "0px 5px", cursor: "pointer" }}
            />
          </div>
        </DialogTitle>

        <DialogContent>
          <div>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2 }}
            >
              <TextField
                {...disabled}
                {...formik.getFieldProps("type")}
                {...(formik.touched.type &&
                  formik.errors.type && { error: true })}
                helperText={(formik.touched.type && formik.errors.type) || " "}
                select
                label="Type Of Leave"
                variant="filled"
                sx={{ width: "100%", padding: "5px" }}
                onChange={handleType}
              >
                {typeOfLeaves.map((type) => {
                  if (props.remainingLeaves[type.concat("leaves")] > 0) {
                    return (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    );
                  } else {
                    if (type === "unpaid") {
                      return (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      );
                    }
                    return (
                      <MenuItem disabled key={type} value={type}>
                        {type}
                      </MenuItem>
                    );
                  }
                })}
              </TextField>

              <TextField
                {...disabled}
                {...formik.getFieldProps("status")}
                {...(formik.touched.status &&
                  formik.errors.status && { error: true })}
                helperText={
                  (formik.touched.status && formik.errors.status) || " "
                }
                select
                label="status"
                variant="filled"
                sx={{ width: "100%", padding: "5px" }}
              >
                {leaveStatus.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2 }}
            >
              <TextField
                {...disabled}
                {...formik.getFieldProps("fromdate")}
                {...(formik.touched.fromdate &&
                  formik.errors.fromdate && { error: true })}
                helperText={
                  (formik.touched.fromdate && formik.errors.fromdate) || " "
                }
                type="date"
                label="From Date"
                variant="filled"
                sx={{ width: "100%", padding: "5px" }}
                onChange={handleFromDate}
              ></TextField>

              <TextField
                {...disabled}
                {...formik.getFieldProps("todate")}
                {...(formik.touched.todate &&
                  formik.errors.todate && { error: true })}
                helperText={
                  (formik.touched.todate && formik.errors.todate) || " "
                }
                type="date"
                label="To Date"
                variant="filled"
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
                onChange={handleToDate}
              ></TextField>
            </Stack>

            {/*below is reason field*/}
            <TextField
              {...disabled}
              {...formik.getFieldProps("reason")}
              {...(formik.touched.reason &&
                formik.errors.reason && { error: true })}
              helperText={
                (formik.touched.reason && formik.errors.reason) || " "
              }
              label="Reason"
              minRows={3}
              multiline
              variant="filled"
              placeholder="Leave Reason"
              sx={{ width: "100%", padding: "5px" }}
            />

            {formik.values.status === "rejected" && (
              <TextField
                {...disabled}
                {...formik.getFieldProps("rejectreason")}
                label="Reject Reason"
                minRows={3}
                multiline
                variant="filled"
                placeholder="Reject Reason"
                sx={{ width: "100%", padding: "5px" }}
              />
            )}
            <FormControlLabel
              {...disabled}
              {...formik.getFieldProps("halfday")}
              {...(formik.touched.halfday &&
                formik.errors.halfday && { error: true })}
              control={<Checkbox checked={formik.values.halfday} />}
              label="Half Day"
              name="halfday"
              sx={{ flex: "1", width: "50%", paddingLeft: "5px" }}
            />
          </div>
        </DialogContent>

        <DialogActions>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "nowrap",
              justifyContent: "space-between",
            }}
          >
            {UserPermissions.leave.status ? (
              <>
                <Button
                  disabled={
                    props.dialogData.status !== "pending" && errorMsg === ""
                  }
                  variant="contained"
                  style={{ margin: "0px 2px" }}
                  onClick={() =>
                    setStatusDialog({ open: true, mode: "approve" })
                  }
                >
                  APPROVE
                </Button>

                <Button
                  disabled={props.dialogData.status !== "pending"}
                  variant="contained"
                  style={{ margin: "0px 2px" }}
                  onClick={() =>
                    setStatusDialog({ open: true, mode: "reject" })
                  }
                >
                  REJECT
                </Button>
              </>
            ) : (
              <>
                {disabled["disabled"] === false && (
                  <Button
                    onClick={() => {
                      formik.resetForm();
                    }}
                    variant="contained"
                  >
                    RESET
                  </Button>
                )}

                <DeleteUpdatePermission
                  handler={formik.handleSubmit}
                  access={props.access}
                  text="UPDATE"
                  permission="update"
                  disabled={!(formik.dirty && formik.isValid)}
                />
              </>
            )}
          </div>
        </DialogActions>
      </Dialog>

      {/*below is delete dialog*/}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
      >
        <DialogTitle>Delete</DialogTitle>

        <DialogContent>
          <DeleteText access={props.access} permission="delete" />
        </DialogContent>

        <DialogActions>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "nowrap",
              justifyContent: "space-between",
            }}
          >
            <DeleteUpdatePermission
              handler={() => deleteHandler()}
              access={props.access}
              text="Delete"
              permission="delete"
              color="red"
            />
            <Button
              onClick={() => {
                setDeleteDialogOpen(false);
              }}
              variant="contained"
            >
              CANCEL
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <StatusDialog
        open={statusDialog.open}
        mode={statusDialog.mode}
        onClose={() => setStatusDialog({ open: false, mode: "" })}
        handleClose={handleClose}
        id={props.dialogData._id}
        tableUpdater={props.tableUpdater}
        rows={props.rows}
      />

      <DisplaySnackbar
        open={snackbarOpen.open}
        closeSnackbar={() => {
          setSnackbarOpen({ open: false, severity: "", message: "" });
        }}
        message={snackbarOpen.message}
        severity={snackbarOpen.severity}
        statusCode={snackbarOpen.statusCode}
      />
    </>
  );
};
export default LeaveUpdateDialog;
