import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {
  Box,
  Container,
  Typography,
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CareerCard from "./CareerCard";
import { careers } from "../../shared/HTTP/HttpCalls";
import DisplaySnackbar from "../../shared/Components/DisplaySnackbar";

const technologyList = [
  { name: "humanresource", title: "Human Resource" },
  { name: "engineering", title: "Engineering" },
  { name: "salesandmarketing", title: "Sales and Marketing" },
  { name: "others", title: "Others" },
];

const snackbarData = {
  open: false,
  severity: "",
  message: "",
  statusCode: "",
};

function Career() {
  const [technologies, setTechnologies] = React.useState([]);
  const [careersData, setCareersData] = React.useState([]);
  const [filterCareersData, setFilterCareersData] = React.useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(snackbarData);

  const snackbarStart = (severity, message, st = "", sc = "") => {
    setSnackbarOpen({
      open: true,
      message: message,
      severity: severity,
      statusCode: `${st} ${sc}`,
    });
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    if (name === "all") {
      let tempTechnologies = technologies.map((technology) => {
        return { ...technology, isChecked: checked };
      });
      setFilterCareersData(careersData);
      setTechnologies(tempTechnologies);
      return;
    }
    let tempTechnologies = technologies.map((technology) =>
      technology.name === name
        ? { ...technology, isChecked: checked }
        : technology
    );

    const filterCareers = careersData.filter((career) => {
      return tempTechnologies.some((technology) => {
        return technology.isChecked && technology.name === career.type;
      });
    });
    setFilterCareersData(filterCareers);
    setTechnologies(tempTechnologies);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  React.useEffect(() => {
    const fetchCareers = async () => {
      try {
        const careersRes = await careers.findAll();
        setFilterCareersData(careersRes.data);
        setCareersData(careersRes.data);
      } catch (err) {
        console.log(err);
      }
    };

    setTechnologies(technologyList);
    return fetchCareers();
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container>
        <Container maxWidth="xl">
          <Box sx={{ bgcolor: "rgb(6,21,41)", pt: 5, pb: 5, pl: 5, pr: 5 }}>
            <Typography
              sx={{ fontWeight: "bold" }}
              variant="h4"
              align="center"
              gutterBottom
              color="white"
            >
              CAREER
            </Typography>
            <Typography variant="h6" align="center" color="white" paragraph>
              We are always looking for talented people to join our team. <br />{" "}
              If you are interested in working with us, please send us your
              resume.
            </Typography>
          </Box>
          <Box sx={{ mt: 5 }}>
            {/* SNACK BAR  */}
            <DisplaySnackbar
              open={snackbarOpen.open}
              closeSnackbar={() => {
                setSnackbarOpen({ open: false, severity: "", message: "" });
              }}
              message={snackbarOpen.message}
              severity={snackbarOpen.severity}
              statusCode={snackbarData.statusCode}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3}>
                <Item>
                  <FormControl
                    sx={{ m: 3 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            name="all"
                            checked={
                              technologies.filter(
                                (technology) => technology?.isChecked !== true
                              ).length < 1
                            }
                          />
                        }
                        label="All"
                      />
                      {technologies.map((technology, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={technology.isChecked || false}
                              onChange={handleChange}
                              name={technology.name}
                            />
                          }
                          label={technology.title}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Item>
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <Grid container spacing={2}>
                  {filterCareersData?.map((career) => (
                    <CareerCard
                      key={career._id}
                      data={career}
                      snackbarStart={snackbarStart}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Container>
    </React.Fragment>
  );
}

export default Career;
