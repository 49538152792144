import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { vertical,horizontal } from '../global-constants';

export const snackbarStart = (severity, message, st = "", sc = "") => {
  return ({
    open: true,
    message: message,
    severity: severity,
    statusCode: `${st} ${sc}`
  });
};
export const snackbarData = {
  open: false,
  severity: "",
  message: "",
  statusCode: ""
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DisplaySnackbar = (props) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.closeSnackbar();
  };

  return (
    <Stack data-testid="snackbar-component" spacing={2} sx={{ width: '100%' }}>
      {props.severity && <Snackbar  anchorOrigin={{vertical,horizontal}} open={props.open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={props.severity} sx={{ width: '100%' }}>
          {props.statusCode} {props.message}
        </Alert>
      </Snackbar>}
      {/* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */}
    </Stack>
  );
};
export default DisplaySnackbar;