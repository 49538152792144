import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import React, { useState } from 'react'
import { skills } from '../../../../shared/HTTP/HttpCalls';
import ReactStars from 'react-rating-stars-component';
import AddPermission from '../../../../shared/Components/AddPermission';
import CloseIcon from "@mui/icons-material/Close";
import { SkillscoreSchema } from '../../../../shared/FormValidationSchema';
import { useFormik } from "formik";

const AddSkills = (props) => {
    const [open, setOpen] = useState(false)

    const onSubmit = (formData) => {
        skills
            .create(formData)
            .then(res => {
                props.fetchSkills()
                props.snackbarStart("success", `Record Added`, res.statusText, res.status);
            })
            .catch((err) => {
                props.snackbarStart("error", err.toString());
            })
        resetHandler();
    };

    const formik = useFormik({
        initialValues: {
            name: "",
            aspiringscore: 0,
            currentscore: 0
        },
        onSubmit,
        validationSchema: SkillscoreSchema
    });

    const resetHandler = () => {
        formik.resetForm();
        setOpen(false);
    };

    return (
        <div data-testid="addskillsComponent">
            {/* DIALOG FOR ADDING DATA  */}
            <Dialog
                open={open}
                onClose={resetHandler}
            >
                <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
                    Skillstores Info
                    <div
                        id="icons"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            cursor: "pointer",
                        }}
                    >
                        <CloseIcon onClick={resetHandler} />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Stack>
                        <TextField
                            {...formik.getFieldProps("name")}
                            {...(formik.errors.name && formik.touched.name && { error: true })}
                            helperText={(formik.touched.name && formik.errors.name) || " "}
                            style={{ width: "100%" }}
                            label="Technology Name"
                            variant="filled"
                        />
                        <Stack
                            textAlign="center"
                            direction={{ xs: 'column' }}
                            margin={2}
                        >
                            <span>Current Score</span>
                            <Stack
                                margin={"auto"}
                            >
                                <ReactStars
                                    value={Number(formik.values.currentscore)}
                                    onChange={(n) => { formik.setFieldValue("currentscore", n); }}
                                    id="currentscore"
                                    color={"rgba(0, 0, 0, 0.26)"}
                                    count={5}
                                    size={30}
                                    activeColor="#faaf00"
                                />
                            </Stack>
                            <span>Aspiring Score</span>
                            <Stack
                                margin={"auto"}
                            >
                                <ReactStars
                                    value={Number(formik.values.aspiringscore)}
                                    onChange={(n) => { formik.setFieldValue("aspiringscore", n); }}
                                    id="aspiringscore"
                                    color={"rgba(0, 0, 0, 0.26)"}
                                    count={5}
                                    size={30}
                                    activeColor="#faaf00"
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button
                        data-testid="submit"
                        disabled={!(formik.dirty && formik.isValid)}
                        variant="contained"
                        onClick={formik.handleSubmit}
                        autoFocus
                    >
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>
            {/*ADD BUTTON */}

            <AddPermission
                access={props.access}
                handler={() => { setOpen(true) }}
                permission="create"
            />
        </div>
    );
};
export default AddSkills;