import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import DeleteDialog from "./DeleteDialog";
import { clients } from "../../../../shared/HTTP/HttpCalls";
import { ClientSchema } from "../../../../shared/FormValidationSchema";
import { useFormik } from "formik";

const DialogData = (props) => {
  const [disabled, setDisabled] = React.useState({ disabled: true });
  const [dialogDelete, setDialogDelete] = useState(false);
  const [edit, setEdit] = useState(false);

  //DELETE DATA HANDLER
  const removeDataHandler = () => {
    clients
      .delete(props.userData.id)
      .then((response) => {
        props.fetchData();
        props.snackbarStart(
          "success",
          `Record Deleted`,
          response.statusText,
          response.status
        );
      })
      .catch((err) => {
        console.log(err);
      });
    handleClose();
  };
  //UPDATE DATA HANDLER
  const onSubmit = (formData) => {
    clients
      .update(props.userData.id, formData)
      .then((response) => {
        props.fetchData();
        props.snackbarStart(
          "success",
          `Record Updated`,
          response.statusText,
          response.status
        );
      })
      .catch((err) => {
        console.log(err);
      });
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      name: props.userData?.name,
      company: props.userData?.company,
      address: props.userData?.address,
      email: props.userData?.email,
      phonenumber: props.userData?.phonenumber,
    },
    onSubmit,
    validationSchema: ClientSchema,
    enableReinitialize: true,
  });

  const handleClose = () => {
    props.setOpen(false);
    setDisabled({ disabled: true });
    setDialogDelete(false);
    setEdit(false);
    formik.resetForm();
  };

  const editHandler = () => {
    if (disabled["disabled"] === true) {
      setDisabled({ disabled: false });
      setEdit(true);
    } else {
      setDisabled({ disabled: true });
      setEdit(false);
      formik.resetForm();
    }
  };

  return (
    <div data-testid="dialogData-component">
      <Dialog open={props.open} onClose={handleClose}>
        <DialogContent>
          <div
            style={{
              marginLeft: 2,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography>Client Info</Typography>
            </div>
            {/* ICONS -- EDIT , DELETE ,CLOSE*/}
            <div style={{ color: "grey" }}>
              <DeleteIcon
                style={{ margin: "0 5px", cursor: "pointer", color: "black" }}
                onClick={() => {
                  setDialogDelete(true);
                  formik.resetForm();
                  setDisabled({ disabled: true });
                }}
              />
              <EditIcon
                style={{ margin: "0 5px", cursor: "pointer", color: "black" }}
                onClick={editHandler}
              />
              <CloseIcon
                style={{ margin: "0 5px", cursor: "pointer", color: "black" }}
                onClick={handleClose}
              />
            </div>
          </div>
          {/* CONDITIONAL RENDERING FOR EDIT AND DATA */}
          {
            <div>
              {/* EDIT */}
              {/* NAME & COMPANY */}
              <div style={{ display: "flex", marginTop: "30px" }}>
                <TextField
                  {...disabled}
                  {...formik.getFieldProps("name")}
                  {...(formik.touched.name &&
                    formik.errors.name && { error: true })}
                  helperText={
                    (formik.touched.name && formik.errors.name) || " "
                  }
                  variant="filled"
                  label="Name"
                />
                <TextField
                  {...disabled}
                  {...formik.getFieldProps("company")}
                  {...(formik.touched.company &&
                    formik.errors.company && { error: true })}
                  helperText={
                    (formik.touched.company && formik.errors.company) || " "
                  }
                  style={{ paddingLeft: 8 }}
                  label="Company"
                  variant="filled"
                />
              </div>
              {/* ADDRESS */}
              <div style={{ paddingTop: 10 }}>
                <TextField
                  {...disabled}
                  {...formik.getFieldProps("address")}
                  {...(formik.touched.address &&
                    formik.errors.address && { error: true })}
                  helperText={
                    (formik.touched.address && formik.errors.address) || " "
                  }
                  style={{ width: "100%" }}
                  label="Address"
                  variant="filled"
                  multiline
                />
              </div>
              {/* EMAIL AND PHONE */}
              <div style={{ display: "flex", marginTop: "10px" }}>
                <TextField
                  {...disabled}
                  {...formik.getFieldProps("email")}
                  {...(formik.touched.email &&
                    formik.errors.email && { error: true })}
                  helperText={
                    (formik.touched.email && formik.errors.email) || " "
                  }
                  label="Email"
                  variant="filled"
                />
                <TextField
                  {...disabled}
                  {...formik.getFieldProps("phonenumber")}
                  {...(formik.touched.phonenumber &&
                    formik.errors.phonenumber && { error: true })}
                  helperText={
                    (formik.touched.phonenumber && formik.errors.phonenumber) ||
                    " "
                  }
                  style={{ paddingLeft: 8 }}
                  label="Phone Number"
                  variant="filled"
                />
              </div>
            </div>
          }
          {/* CONDITIONAL RENDERING FOR EDIT AND DATA CLOSE*/}
        </DialogContent>

        <DialogActions>
          {edit ? (
            <>
              <Button onClick={formik.resetForm} variant="contained">
                Reset
              </Button>
              <Button
                disabled={!(formik.dirty && formik.isValid)}
                onClick={formik.handleSubmit}
                variant="contained"
              >
                Update
              </Button>
            </>
          ) : (
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <DeleteDialog
        open={dialogDelete}
        setDialogDelete={setDialogDelete}
        removeDataHandler={removeDataHandler}
        setOpen={props.setOpen}
      />
    </div>
  );
};
export default DialogData;
