import React from 'react'
import FaqList from "./FaqList"
const FAQ = () => {
  return (
    <div data-testid="faq-component">
     {/*FAQ LIST */}
      <FaqList/>
    </div>
  );
};
export default FAQ;