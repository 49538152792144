import React from "react";
import { Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { useFormik } from "formik";
import { kbSchema } from "../../../../shared/FormValidationSchema";
import { Upload, KB } from "../../../../shared/HTTP/HttpCalls";
import { snackbarStart } from "../../../../shared/Components/DisplaySnackbar";

const KbCreateDialog = (props) => {

    const [file, setFile] = React.useState([]);

    const onSubmit = (formData) => {
        Upload.create(file)
            .then(res => {
                const createKB = {
                    name: formData.name,
                    description: formData.description,
                    version: formData.version,
                    filesrc: res.data[0]._id
                };
                console.log(res);
                KB.create(createKB)
                    .then((response) => {
                        props.setSnackbarOpen(snackbarStart("success", "Card Added", response.statusText, response.status));
                        props.cardsUpdater([...props.data, response.data]);
                    });
            })
            .catch(err => console.log(err))
        handleClose();
    };

    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            version: ""
        },
        onSubmit,
        validationSchema: kbSchema
    });

    const handleFiles = (event) => {
        let fData = new FormData();
        fData.append(event.target.name, event.target.files[0]);
        setFile(fData);
    };

    const handleClose = () => {
        props.dialogClose();
        formik.resetForm();
        setFile([]);
    };

    return (
        <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth="xs">

            <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
                Create new
                <div id="icons" style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", justifyContent: "space-between" }}>
                    <CloseIcon
                        onClick={handleClose}
                        sx={{ margin: "0px 5px", cursor: "pointer" }}
                    />
                </div>
            </DialogTitle>

            <DialogContent>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 0 }}
                    sx={{ mb: 2 }}
                >
                    <TextField
                        {...formik.getFieldProps("name")}
                        {... (formik.touched.name && formik.errors.name && { error: true })}
                        helperText={(formik.touched.name && formik.errors.name) || " "}
                        label="Name"
                        variant="filled"
                        sx={{ width: "100%", padding: "5px" }}
                    >
                    </TextField>
                    <TextField
                        {...formik.getFieldProps("version")}
                        {... (formik.touched.version && formik.errors.version && { error: true })}
                        helperText={(formik.touched.version && formik.errors.version) || " "}
                        label="Version"
                        variant="filled"
                        sx={{ width: "100%", padding: "5px" }}
                    >
                    </TextField>
                </Stack>
                <TextField
                    {...formik.getFieldProps("description")}
                    {...(formik.touched.description && formik.errors.description && { error: true })}
                    helperText={(formik.touched.description && formik.errors.description) || " "}
                    label="Description"
                    minRows={3}
                    multiline
                    variant="filled"
                    placeholder="Enter Description"
                    sx={{ width: "100%", padding: "5px" }}
                />
            </DialogContent>

            <DialogActions>
                <div style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", justifyContent: "space-between" }}>
                    <Button
                        variant="contained"
                        component="label"
                        sx={{ margin: "0px 2px" }}
                    >
                        {file.length !== 0 ? "ATTACHED" : "UPLOAD FILE"}
                        <input
                            id="files" name="files"
                            type="file"
                            onChange={handleFiles}
                            hidden
                        />
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ margin: "0px 2px" }}
                        onClick={formik.handleSubmit}
                        disabled={!(formik.isValid && formik.dirty) || file.length === 0}
                    >
                        CREATE
                    </Button>
                </div>
            </DialogActions>

        </Dialog>
    );
};
export default KbCreateDialog;