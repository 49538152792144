import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react'
import DeleteText from '../../../../shared/Components/DeleteText';
import DeleteUpdatePermission from '../../../../shared/Components/DeleteUpdatePermission';

const DeleteProjects = (props) => {
    return (
        <div data-testid="deleteProjects-component">
            <Dialog open={props.open} >
                <DialogContent>
                        <DeleteText 
                            access={props.access}
                            permission="delete"
                        />
                </DialogContent>
                <DialogActions>
                    {/* Delete button */}
                    <DeleteUpdatePermission
                        access={props.access}
                        handler={()=>{props.removeDataHandler(); props.setDeleteDialog(false); props.setOpen(false)}}
                        text="DELETE"
                        permission="delete"
                        color="red"
                    />
                    {/* Cancel button */}
                    <Button
                        variant="contained"
                        onClick={() => props.setDeleteDialog(false)}
                        autoFocus
                        style={{marginLeft:"5px"}}
                    >
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default DeleteProjects;