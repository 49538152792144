import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import ReactMarkdown from "react-markdown";
import ReCAPTCHA from "react-google-recaptcha";
import { jobApplications } from "../../shared/HTTP/HttpCalls";
import { JobApplicationSchema } from "../../shared/FormValidationSchema";
import { recaptchaSiteKey } from "../../shared/global-constants";

function CareerModal(props) {
  const [file, setFile] = React.useState([]);
  const [recaptchaToken, setRecaptchaToken] = React.useState("");

  const onSubmit = async (formData) => {
    formData.career = { _id: props.dialogData.id };

    const data = new FormData();
    data.append("files", file);
    data.append("data", JSON.stringify(formData));
    data.append("recaptchaToken", recaptchaToken);

    const response = await jobApplications.create(data);

    if (response.status === 200) {
      props.snackbarStart(
        "success",
        "Resume submitted successfully. We will get back to you soon.",
        response.statusText,
        response.status
      );
      props.onClose();
    } else {
      props.snackbarStart(
        "error",
        "Something went wrong. Please try again.",
        response.statusText,
        response.status
      );
      props.onClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      mobilenumber: "",
    },
    onSubmit,
    validationSchema: JobApplicationSchema,
  });

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleRecaptcha = (value) => {
    setRecaptchaToken(value);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle
        id="dialog-title"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        {props.dialogData.position}
        <div id="icons" style={{ display: "flex", alignItems: "center" }}>
          <CloseIcon onClick={props.onClose} sx={{ cursor: "pointer" }} />
        </div>
      </DialogTitle>

      <DialogContent dividers>
        <Typography component={"span"} variant={"body2"}>
          <ReactMarkdown>{props.dialogData.description}</ReactMarkdown>
        </Typography>
        <Box sx={{ mt: 2 }}>
          <form onSubmit={formik.handleSubmit}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2, alignItems: "center" }}
            >
              <TextField
                {...formik.getFieldProps("firstname")}
                {...(formik.touched.firstname &&
                  formik.errors.firstname && { error: true })}
                helperText={
                  (formik.touched.firstname && formik.errors.firstname) || " "
                }
                label="First Name"
                variant="standard"
                fullWidth
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
              />
              <TextField
                {...formik.getFieldProps("lastname")}
                {...(formik.touched.lastname &&
                  formik.errors.lastname && { error: true })}
                helperText={
                  (formik.touched.lastname && formik.errors.lastname) || " "
                }
                label="Last Name"
                variant="standard"
                fullWidth
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2, alignItems: "center" }}
            >
              <TextField
                {...formik.getFieldProps("email")}
                {...(formik.touched.email &&
                  formik.errors.email && { error: true })}
                helperText={
                  (formik.touched.email && formik.errors.email) || " "
                }
                label="Email"
                variant="standard"
                fullWidth
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
              />
              <TextField
                {...formik.getFieldProps("mobilenumber")}
                {...(formik.touched.mobilenumber &&
                  formik.errors.mobilenumber && { error: true })}
                helperText={
                  (formik.touched.mobilenumber && formik.errors.mobilenumber) ||
                  " "
                }
                label="Mobile Number"
                variant="standard"
                fullWidth
                sx={{ minWidth: "50%", maxWidth: "100%", padding: "5px" }}
              />
            </Stack>

            <Stack
              spacing={{ xs: 1, sm: 2, md: 0 }}
              sx={{ mb: 2, alignItems: "center", justifyContent: "center" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 0px",
                }}
              >
                {file.name && <Typography>{file.name}</Typography>}
                <Button variant="contained" component="label" sx={{ ml: 2 }}>
                  {file.length !== 0 ? "ATTACHED" : "UPLOAD RESUME"}
                  <input
                    type="file"
                    name="resume"
                    onChange={handleFileChange}
                    accept=".pdf,.doc,.docx"
                    hidden
                  />
                </Button>
              </Box>

              <ReCAPTCHA
                style={{
                  display: "flex",
                  justifyContent: "center",
                  transform: "scale(0.85)",
                }}
                sitekey={recaptchaSiteKey}
                onChange={handleRecaptcha}
              />
            </Stack>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          variant="contained"
          disabled={
            !(
              formik.isValid &&
              formik.dirty &&
              file.length !== 0 &&
              recaptchaToken.length !== 0
            )
          }
          onClick={formik.handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CareerModal;
