import React from "react";
import PresentMembers from "./PresentMembers";
import SignInOut from "./SignInOut";
import AttendanceTable from "./AttendanceTable";
import { Attendances } from "../../../../shared/HTTP/HttpCalls";
import { UserPermissions } from "../../../../shared/global-constants";
import { getUserId } from "../../../../shared/LocalStorage/LocalStorageCalls";
import AttendanceRecord from "./AttendanceRecord";

const Attendance = () => {
  const [tableData, setTableData] = React.useState([]);
  const [employeesData, setEmployeesData] = React.useState({});

  React.useEffect(() => {
    if (UserPermissions.attendance.find) {
      Attendances.getPresent()
        .then((res) => setEmployeesData(res.data))
        .catch((err) => console.log(err));
      Attendances.findAll({
        _sort: "createdAt:DESC",
      })
        .then((res) => setTableData(res.data))
        .catch((err) => console.log(err));
    } else {
      Attendances.getList(getUserId())
        .then((res) => setTableData(res.data))
        .catch((err) => console.log(err));
    }
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "160px",
        }}
      >
        {UserPermissions.attendance.find && (
          <PresentMembers employeesData={employeesData} />
        )}
        <SignInOut />
      </div>
      <br />
      <AttendanceTable data={tableData} />
      <br />
      {UserPermissions.attendance.find && <AttendanceRecord />}
    </>
  );
};
export default Attendance;
