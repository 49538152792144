import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Stack, TextField, Typography } from '@mui/material';
import { useFormik } from "formik";
import AddPermission from "../../../../shared/Components/AddPermission";
import { clients } from '../../../../shared/HTTP/HttpCalls';
import { getClientPermission } from '../../../../shared/Permission/permissionAccess';
import { ClientSchema } from '../../../../shared/FormValidationSchema';

const AddData = ({ fetchData, snackbarStart }) => {

    const [open, setOpen] = React.useState(false);
    
    const onSubmit = (formData, { resetForm }) => {
        clients.create(formData)
            .then((response) => {
                fetchData()
                snackbarStart("success", `Record Added`, response.statusText, response.status);
            })
            .catch(err => console.log(err.message));
        setOpen(false);
        resetForm();
    };

    const formik = useFormik({
        initialValues: {
            name: "",
            company: "",
            address: "",
            email: "",
            phonenumber: ""
        },
        onSubmit,
        validationSchema: ClientSchema
    });

    return (
        <div data-testid="addData-component">
            {/* DIALOG */}
            <Dialog open={open} onClose={() => { formik.resetForm(); setOpen(false); }}>
                {/* DIALOG CONTENT */}
                <DialogContent>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Stack>
                            <Typography>Add Client</Typography>
                        </Stack>
                        <Stack>
                            <CloseIcon onClick={() => { formik.resetForm(); setOpen(false); }} />
                        </Stack>
                    </Stack>

                    {/* NAME & COMPANY */}
                    <div style={{ display: "flex", marginTop: "30px" }}>
                        <TextField
                            {...formik.getFieldProps("name")}
                            {...(formik.touched.name && formik.errors.name && { error: true })}
                            helperText={(formik.touched.name && formik.errors.name) || " "}
                            label="Name"
                            variant="filled"
                        />
                        <TextField
                            {...formik.getFieldProps("company")}
                            {...(formik.touched.company && formik.errors.company && { error: true })}
                            helperText={(formik.touched.company && formik.errors.company) || " "}
                            style={{ paddingLeft: 8 }}
                            label="Company"
                            variant="filled"
                        />
                    </div>
                    {/* ADDRESS */}
                    <div style={{ paddingTop: 10 }}>
                        <TextField
                            {...formik.getFieldProps("address")}
                            {...(formik.touched.address && formik.errors.address && { error: true })}
                            helperText={(formik.touched.address && formik.errors.address) || " "}
                            style={{ width: "100%" }}
                            label="Address"
                            variant="filled"
                        />
                    </div>
                    {/* EMAIL & PHONE */}
                    <div style={{ display: "flex", marginTop: "10px" }}>
                        <TextField
                            {...formik.getFieldProps("email")}
                            {...(formik.touched.email && formik.errors.email && { error: true })}
                            helperText={(formik.touched.email && formik.errors.email) || " "}
                            label="Email"
                            variant="filled"
                        />
                        <TextField
                            {...formik.getFieldProps("phonenumber")}
                            {...(formik.touched.phonenumber && formik.errors.phonenumber && { error: true })}
                            helperText={(formik.touched.phonenumber && formik.errors.phonenumber) || " "}
                            style={{ paddingLeft: 8 }}
                            label="Phone"
                            variant="filled"
                        />
                    </div>
                </DialogContent>
                {/* DIALOG ACTIONS BUTTON */}
                <DialogActions>
                    <Button
                        onClick={formik.handleSubmit}
                        disabled={!(formik.dirty && formik.isValid)}
                        variant="contained"
                        autoFocus
                    >
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>
            {/* ADD CLIENT TEXT */}
            <AddPermission
                access={getClientPermission}
                handler={() => { setOpen(true) }}
                permission="create"
            />
        </div>
    );
};
export default AddData;