import Service from "./Http";
import { API } from "../global-constants";

const services = new Service();

export const auth = {
  newLogin(data) {
    return services.post(API.userAuthEndpoint, data);
  },
};

export const Attendances = {
  findAll(query) {
    return services.get(API.attendanceEndpoint, "", query);
  },
  findOne(id) {
    return services.get(API.attendanceEndpoint, id);
  },
  create(data) {
    return services.post(API.attendanceEndpoint, data);
  },
  update(id, data) {
    return services.put(API.attendanceEndpoint, id, data);
  },
  delete(id) {
    return services.delete(API.attendanceEndpoint, id);
  },
  getPresent() {
    return services.get(API.getPresentEndpoint);
  },
  signIn(id) {
    return services.get(API.signInEndpoint, id);
  },
  signOut(id) {
    return services.get(API.signOutEndpoint, id);
  },
  getList(id) {
    return services.get(API.attendanceGetListEndpoint, id);
  },
  checkSignIn(id) {
    return services.get(API.checkSignInEndpoint, id);
  },
  getAttendanceCount(month, year) {
    const filterEndpoint = `${API.getAttendanceCountEndpoint}/${month}/${year}`;
    return services.get(filterEndpoint);
  },
};

export const project = {
  findAll() {
    return services.get(API.projectEndPoint);
  },
  findOne(id) {
    return services.get(API.projectEndPoint, id);
  },
  create(data) {
    return services.post(API.projectEndPoint, data);
  },
  update(id, data) {
    return services.put(API.projectEndPoint, id, data);
  },
  delete(id) {
    return services.delete(API.projectEndPoint, id);
  },
};

export const faq = {
  findAll() {
    return services.get(API.faqEndPoint);
  },
  findOne(id) {
    return services.get(API.faqEndPoint, id);
  },
  create(data) {
    return services.post(API.faqEndPoint, data);
  },
  update(id, data) {
    return services.put(API.faqEndPoint, id, data);
  },
  delete(id) {
    return services.delete(API.faqEndPoint, id);
  },
};

export const clients = {
  findAll() {
    return services.get(API.clientEndpoint);
  },
  findOne(id) {
    return services.get(API.clientEndpoint, id);
  },
  create(data) {
    return services.post(API.clientEndpoint, data);
  },
  update(id, data) {
    return services.put(API.clientEndpoint, id, data);
  },
  delete(id) {
    return services.delete(API.clientEndpoint, id);
  },
};

export const users = {
  findAll() {
    return services.get(API.userEndpoint);
  },
  findOne(id) {
    return services.get(API.userEndpoint, id);
  },
  create(data) {
    return services.post(API.userEndpoint, data);
  },
  update(id, data) {
    return services.put(API.userEndpoint, id, data);
  },
  delete(id) {
    return services.delete(API.userEndpoint, id);
  },
};

export const skills = {
  findAll() {
    return services.get(API.skills);
  },
  findOne(id) {
    return services.get(API.skills, id);
  },
  create(data) {
    return services.post(API.skills, data);
  },
  update(id, data) {
    return services.put(API.skills, id, data);
  },
  delete(id) {
    return services.delete(API.skills, id);
  },
};

export const userMeta = {
  findAll() {
    return services.get(API.userMetaEndpoint);
  },
  findOne(id) {
    return services.get(API.userMetaEndpoint, id);
  },
  create(data) {
    return services.post(API.userMetaEndpoint, data);
  },
  update(id, data) {
    return services.put(API.userMetaEndpoint, id, data);
  },
  delete(id) {
    return services.delete(API.userMetaEndpoint, id);
  },
};

export const userAuth = {
  findAll() {
    return services.get(API.userAuthEndpoint);
  },
  findOne(id) {
    return services.get(API.userAuthEndpoint, id);
  },
  create(data) {
    return services.post(API.userAuthEndpoint, data);
  },
  update(id, data) {
    return services.put(API.userAuthEndpoint, id, data);
  },
  delete(id) {
    return services.delete(API.userAuthEndpoint, id);
  },
};

export const leaves = {
  findAll() {
    return services.get(API.leavesEndpoint);
  },
  findOne(id) {
    return services.get(API.leavesEndpoint, id);
  },
  create(data) {
    return services.post(API.leavesEndpoint, data);
  },
  update(id, data) {
    return services.put(API.leavesEndpoint, id, data);
  },
  delete(id) {
    return services.delete(API.leavesEndpoint, id);
  },
  getList(id) {
    return services.get(API.leavesGetListEndpoint, id);
  },
  remainingLeaves(id) {
    return services.get(API.leavesRemainingEndpoint, id);
  },
};

export const leavesStatus = {
  update(id, data) {
    return services.put(API.leavesStatusEndpoint, id, data);
  },
};

export const roles = {
  findAll() {
    return services.get(API.rolesEndpoint);
  },
};

export const finances = {
  findAll() {
    return services.get(API.financeEndpoint);
  },
  findOne(id) {
    return services.get(API.financeEndpoint, id);
  },
  create(data) {
    return services.post(API.financeEndpoint, data);
  },
  update(id, data) {
    return services.put(API.financeEndpoint, id, data);
  },
  delete(id) {
    return services.delete(API.financeEndpoint, id);
  },
};

export const KB = {
  findAll() {
    return services.get(API.kbEndpoint);
  },
  findOne(id) {
    return services.get(API.kbEndpoint, id);
  },
  create(data) {
    return services.post(API.kbEndpoint, data);
  },
  update(id, data) {
    return services.put(API.kbEndpoint, id, data);
  },
  delete(id) {
    return services.delete(API.kbEndpoint, id);
  },
};

export const Upload = {
  create(data) {
    return services.post(API.uploadEndpoint, data);
  },
};

export const Uploads = {
  getFileContents(url) {
    return services.get(url); //to get the actual file contents and access uploaded files directly
  },
};

export const Media = {
  findAll() {
    return services.get(API.mediaEndpoint);
  },
  findOne(id) {
    return services.get(API.mediaEndpoint, id);
  },
  delete(id) {
    return services.delete(API.mediaEndpoint, id);
  },
};

export const permission = {
  findOne(id) {
    return services.get(API.permissionsEndpoint, id);
  },
};

export const careers = {
  findAll(query) {
    return services.get(API.careerEndPoint, "", query);
  },
};

export const jobApplications = {
  findAll() {
    return services.get(API.jobApplicationEndPoint);
  },
  create(data) {
    return services.post(API.jobApplicationEndPoint, data);
  },
};
