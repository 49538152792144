import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import DisplaySnackbar from "../../../../shared/Components/DisplaySnackbar";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { leavesStatus } from "../../../../shared/HTTP/HttpCalls";
import { LeaveStatusSchema } from "../../../../shared/FormValidationSchema";
import {
  snackbarData,
  snackbarStart,
} from "../../../../shared/Components/DisplaySnackbar";

const StatusDialog = (props) => {
  const [snackbarOpen, setSnackbarOpen] = React.useState(snackbarData);

  const onRejected = (formData) => {
    leavesStatus
      .update(props.id, { status: "rejected", ...formData })
      .then((res) => {
        setSnackbarOpen(
          snackbarStart("success", "REJECTED", res.statusText, res.status)
        );
      })
      .catch((err) => console.log(err));
    props.handleClose();
  };

  const onApproved = () => {
    leavesStatus
      .update(props.id, { status: "approved" })
      .then((res) => {
        props.tableUpdater(
          props.rows.map((obj) => {
            return obj._id === res.data._id ? res.data : obj;
          })
        );
        setSnackbarOpen(
          snackbarStart("success", "APPROVED", res.statusText, res.status)
        );
      })
      .then(() => {
        props.handleClose();
      })
      .catch((err) => console.log(err));
  };

  const formik = useFormik({
    initialValues: {
      rejectreason: "",
    },
    onSubmit: onRejected,
    validationSchema: LeaveStatusSchema,
  });

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => {
          props.onClose();
          formik.resetForm();
        }}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Confirm {props.mode} ?
          <div
            id="icons"
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "nowrap",
              justifyContent: "space-between",
            }}
          >
            <CloseIcon
              onClick={() => {
                props.onClose();
                formik.resetForm();
              }}
              sx={{ margin: "0px 5px", cursor: "pointer" }}
            />
          </div>
        </DialogTitle>

        {props.mode === "reject" && (
          <DialogContent>
            <TextField
              {...formik.getFieldProps("rejectreason")}
              helperText={
                (formik.touched.rejectreason && formik.errors.rejectreason) ||
                " "
              }
              {...(formik.touched.rejectreason &&
                formik.errors.rejectreason && { error: true })}
              label="Reject Reason"
              minRows={3}
              multiline
              variant="filled"
              placeholder="Reject Reason"
              sx={{ width: "100%", padding: "5px" }}
            />
          </DialogContent>
        )}

        <DialogActions>
          <Button
            disabled={
              props.mode === "reject" && !(formik.dirty && formik.isValid)
            }
            onClick={
              (props.mode === "reject" && formik.handleSubmit) || onApproved
            }
            variant="contained"
          >
            {props.mode.toUpperCase()}
          </Button>
        </DialogActions>
      </Dialog>
      <DisplaySnackbar
        open={snackbarOpen.open}
        closeSnackbar={() => {
          setSnackbarOpen({ open: false, severity: "", message: "" });
        }}
        message={snackbarOpen.message}
        severity={snackbarOpen.severity}
        statusCode={snackbarOpen.statusCode}
      />
    </>
  );
};

export default StatusDialog;
