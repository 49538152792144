import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import React from 'react'
import DeleteText from '../../../../shared/Components/DeleteText'
import DeleteUpdatePermission from '../../../../shared/Components/DeleteUpdatePermission'
import { getClientPermission } from '../../../../shared/Permission/permissionAccess'

const DeleteDialog = (props) => {
    const access = getClientPermission
    return (
        <div data-testid="deleteDialog-component">
            <Dialog
                open={props.open || false}
            >

                <DialogContent>
                    <DeleteText
                        access={access}
                        permission="delete"
                    />
                </DialogContent>
                <DialogActions>
                    <DeleteUpdatePermission
                        access={access}
                        handler={() => { props.setDialogDelete(false); props.removeDataHandler();props.setOpen(false) }}
                        text="DELETE"
                        permission="delete"
                        color="red"
                    />
                    <Button
                        variant="contained"
                        onClick={() => props.setDialogDelete(false)}
                        autoFocus
                        style={{marginLeft:"5px"}}
                    >
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DeleteDialog