import React from 'react'
import {Dialog, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import MUIRichTextEditor from 'mui-rte'
import CloseIcon from "@mui/icons-material/Close";

const ViewData = (props) => {
    const handleClose = () => {
        props.setViewDialog(false)
    }
    return (
        <div data-testid="viewData-component">
            {/*Dialog */}
            <Dialog
                open={props.open || false}
                onClose={handleClose}
            >
                <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
                    FAQ Info
                    <div
                        id="icons"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            cursor: "pointer",
                        }}
                    >
                        <CloseIcon onClick={handleClose} />
                    </div>
                </DialogTitle>
                {/*Dialog Content */}
                <DialogContent>
                    <Stack spacing={1}>
                        <TextField
                            value={props.rowData?.question}
                            variant="filled"
                            disabled
                            multiline
                        />

                        <Stack style={{ width: "100%" }}>
                            <MUIRichTextEditor
                                controls={[]}
                                readOnly
                                defaultValue={props.rowData?.answer}
                            />
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
        </div>
    );
};
export default ViewData;