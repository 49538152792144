import React from "react";
import {
  CardActions,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";
import CareerModal from "./CareerModal";

function CareerCard({ data, snackbarStart }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card
        sx={{
          maxWidth: 450,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {data.position}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {data.description.substring(0, 100)}...
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions sx={{ justifyContent: "center" }}>
          <Button size="small" color="primary" onClick={handleDialogOpen}>
            Apply
          </Button>
        </CardActions>
      </Card>

      <CareerModal
        snackbarStart={snackbarStart}
        open={dialogOpen}
        onClose={() => {
          handleDialogClose();
        }}
        dialogData={data}
      />
    </Grid>
  );
}

export default CareerCard;
