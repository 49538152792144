import React from 'react'
import { Card, Chip, Dialog, DialogContent } from '@mui/material';
import { clients, project } from '../../../../shared/HTTP/HttpCalls';
import { DataGrid } from '@mui/x-data-grid';
import ProjectData from './ProjectData';
import AddProjects from './AddProjects';
import DisplaySnackbar from '../../../../shared/Components/DisplaySnackbar';
import { getProjectPermission } from '../../../../shared/Permission/permissionAccess';

const snackbarData = {
    open: false,
    severity: "",
    message: "",
    statusCode: ""
};

const Projects = () => {
    const [details, setDetails] = React.useState({})
    const [open, setOpen] = React.useState(false);
    const [res, setRes] = React.useState([])
    const [clientData, setClientData] = React.useState([])
    const [snackbarOpen, setSnackbarOpen] = React.useState(snackbarData);

    const access=getProjectPermission

    const handleDialog = (params) => {
        setOpen(true)
        setDetails(params)
    }
    //set snackbar values
    const snackbarStart = (severity, message, st = "", sc = "") => {
        setSnackbarOpen({
            open: true,
            message: message,
            severity: severity,
            statusCode: `${st} ${sc}`
        })
    };
    const columns = [
        {
            field: 'name',
            headerName: 'Project Name',
            width: 150, 
        },
        {
            field: 'names',
            headerName: 'Client Name',
            width: 200,
            renderCell: (params) => {
                return (
                    <span>{params.row.client?.name}</span>
                )
            }
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 200,
        },
        {
            field: 'startdate',
            headerName: 'Start Date',
            width: 200,
        },
        {
            field: 'enddate',
            headerName: 'End Date',
            width: 200,
        },
        {
            field: 'technology',
            headerName: 'Technologies Name',
            width: 300,
            renderCell: (params) => {
                return (
                    params.row.technologies.map((data, index) => (
                        <span key={index} style={{ margin: 4 }}><Chip label={data.technologies} /></span>
                    ))
                )
            }
        },


    ];

    /* FETCH DATA PROJECT */
    const fetchData = () => {
        project
            .findAll()
            .then((res) => {
                setRes(res.data)
            })
            .catch(err => console.log("error: ", err))
    }

    /* FETCH DATA CLIENT */
    const fetchClientDetails = () => {
        clients
            .findAll()
            .then(res => setClientData(res.data))
            .catch(err => console.log("error: ", err))
    }

    React.useEffect(() => {
        fetchData();
        fetchClientDetails();
    }, [])
    return (
        <div data-testid="projectComponent" style={{ height: 400, width: '100%' }}>
            {/* SNACK BAR  */}
            <DisplaySnackbar
                open={snackbarOpen.open}
                closeSnackbar={() => { setSnackbarOpen({ open: false, severity: "", message: "" }) }}
                message={snackbarOpen.message}
                severity={snackbarOpen.severity}
                statusCode={snackbarData.statusCode}
            />
            {/* ADD PROJECTS  */}
            <AddProjects
                clientData={clientData}
                fetchData={fetchData}
                details={details}
                snackbarStart={snackbarStart}
                access={access}
            />
            {/* DIALOG  */}
            <Dialog open={open}>
                <DialogContent>
                    <ProjectData
                        clientData={clientData}
                        res={res}
                        details={details}
                        setOpen={setOpen}
                        open={open}
                        fetchData={fetchData}
                        snackbarStart={snackbarStart}
                        access={access}
                        
                    />
                </DialogContent>
            </Dialog>
            <Card>
                <div style={{ height: 400, width: "100%" }}>
                    <DataGrid
                        rows={res}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        checkboxSelection
                        disableSelectionOnClick
                        onRowClick={(params) => handleDialog(params.row)}
                        sx={{
                            '& .MuiDataGrid-cell:hover': {
                                cursor: "pointer"
                            }
                        }}
                    />
                </div>
            </Card>

        </div>
    )
}

export default Projects