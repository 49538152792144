import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Main from "./Layout/Main";
import Auth from "./Pages/Auth/Auth";
import { getToken } from "./shared/LocalStorage/LocalStorageCalls";
import Career from "./Pages/Career/Career";

const App = () => {
  return (
    <Switch>
      <Route exact path="/career">
        <Career />
      </Route>
      {getToken() ? (
        <Route path="/">
          <Main />
        </Route>
      ) : (
        <Route path="/">
          <Auth />
        </Route>
      )}
      <Redirect from="*" to="/" />
    </Switch>
  );
};

export default App;
