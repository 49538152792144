import * as React from "react";
import UserTable from "./UserTable";
import { users } from "../../../../shared/HTTP/HttpCalls";
import { mapUserWithMeta } from "../../../../shared/utils";

const Members = () => {
  const [tableData, setTableData] = React.useState([]);

  React.useEffect(() => {
    users
      .findAll()
      .then((res) => {
        const userdata = res.data.map((row) => mapUserWithMeta(row));
        setTableData(userdata);
      })
      .catch((err) => console.log("error: ", err));
  }, []);

  const tableUpdater = (newData) => {
    setTableData(newData);
  };

  return (
    <div>
      <UserTable rows={tableData} tableUpdater={tableUpdater} />
    </div>
  );
};

export default Members;
