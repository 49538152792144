import React from "react";
import Card from "@mui/material/Card";
import { DataGrid } from "@mui/x-data-grid";
import UserModal from "./UserModal";
import UserCreateModal from "./UserCreateModal";
import Stack from "@mui/material/Stack";
import { roles } from "../../../../shared/HTTP/HttpCalls";
import AddPermission from "../../../../shared/Components/AddPermission";
import { getMemberPermission } from "../../../../shared/Permission/permissionAccess";
const columns = [
  {
    field: "empid",
    headerName: "Employee Id",
    width: 150,
  },
  {
    field: "username",
    headerName: "Name",
    width: 200,
  },
  {
    field: "role",
    headerName: "Role",
    width: 200,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.role.name}</div>;
    },
  },
  {
    field: "mobileno",
    headerName: "Mobile No.",
    width: 250,
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
  },
];

const UserTable = (props) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogData, setDialogData] = React.useState({});
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [rolesData, setRolesData] = React.useState([]);
  const access = getMemberPermission;
  React.useEffect(() => {
    roles
      .findAll()
      .then((res) => {
        setRolesData(res.data);
      })
      .catch((err) => console.log("error: ", err));
  }, []);

  const rowClickHandler = (param, event) => {
    setDialogOpen(true);
    setDialogData({
      ...param.row,
      role: param.row.role._id,
    });
  };

  return (
    <>
      <Stack
        sx={{ mb: 2 }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 2, sm: 2, md: "1%" }}
      >
        <AddPermission
          handler={() => setCreateDialogOpen(true)}
          access={access}
          permission="create"
        />
      </Stack>
      <Card>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={props.rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            onRowClick={rowClickHandler}
            sx={{
              "& .MuiDataGrid-cell:hover": {
                cursor: "pointer",
              },
            }}
          />
        </div>
      </Card>
      <UserModal
        open={dialogOpen}
        dialogClose={() => {
          setDialogOpen(false);
        }}
        dialogData={dialogData}
        tableUpdater={props.tableUpdater}
        rows={props.rows}
        rolesData={rolesData || ""}
        access={access}
      />
      <UserCreateModal
        open={createDialogOpen}
        dialogClose={() => {
          setCreateDialogOpen(false);
        }}
        tableUpdater={props.tableUpdater}
        rows={props.rows}
        rolesData={rolesData || ""}
      />
    </>
  );
};

export default UserTable;
