import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from "./DeleteDialog";
import UpdateDialog from "./UpdateDialog";
// import FileDialog from "./FileDialog";
import { Media } from "../../../../shared/HTTP/HttpCalls";
import { apiURL } from "../../../../shared/global-constants";
import { UserPermissions } from "../../../../shared/global-constants";

const cardsLayout = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
    alignContent: "space-around",
    rowGap: "10px",
    columnGap: "10px",
};

const KnowledgeCards = (props) => {

    const [deleteDialog, setDeleteDialog] = React.useState({ open: false, data: {} });
    const [updateDialog, setUpdateDialog] = React.useState({ open: false, data: {} });

    const OpenFile = (data) => {
        Media.findOne(data.filesrc)
            .then((res) => {
                window.open(apiURL + res.data.url);
            })
            .catch(err => console.log(err))
    };

    return (
        <>
            <div style={cardsLayout}>
                {props.data.map(data =>
                    <Card key={data._id} sx={{ width: 385, height: 250 }}>

                        <div style={{ textAlign: "right" }}>
                            {UserPermissions.kb.update &&
                                <EditIcon
                                    onClick={() => { setUpdateDialog({ open: true, data: data }); }}
                                    sx={{ margin: "0px 5px", cursor: "pointer" }}
                                />
                            }
                            {
                                UserPermissions.kb.delete &&
                                <DeleteIcon
                                    onClick={() => { setDeleteDialog({ open: true, data: data }); }}
                                    sx={{ margin: "0px 5px", cursor: "pointer" }}
                                />
                            }
                        </div>

                        <CardContent>
                            <Typography variant="h5">
                                {data.name}
                            </Typography>
                            <Button
                                onClick={() => { OpenFile(data) }}
                                variant="contained"
                            >
                                OPEN
                            </Button>
                            <Typography variant="h6">
                                Version: {data.version}
                                <br />
                                Description: {data.description}
                            </Typography>
                        </CardContent>

                    </Card>
                )}
            </div>

            <DeleteDialog
                open={deleteDialog.open}
                dialogData={deleteDialog.data}
                dialogClose={() => { setDeleteDialog({ open: false, data: {} }); }}
                setSnackbarOpen={props.setSnackbarOpen}
                cardsUpdater={props.cardsUpdater}
                data={props.data}
            />

            <UpdateDialog
                open={updateDialog.open}
                dialogData={updateDialog.data}
                dialogClose={() => { setUpdateDialog({ open: false, data: {} }); }}
                setSnackbarOpen={props.setSnackbarOpen}
                cardsUpdater={props.cardsUpdater}
                data={props.data}
            />

            {/* <FileDialog
                open={fileDialog}
                data={fileData}
                dialogClose={() => setFileDialog(false)}
            /> */}

        </>
    );
};
export default KnowledgeCards;