import React, { useEffect, useState } from "react";
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import { clients } from "../../../../shared/HTTP/HttpCalls";
import DisplaySnackbar from "../../../../shared/Components/DisplaySnackbar";
import DialogData from "./DialogData";
import AddData from "./AddData";

const snackbarData = {
    open: false,
    severity: "",
    message: "",
    statusCode: ""
};

const Clients = () => {
    const [userData, setUserData] = useState({})
    const [open, setOpen] = React.useState(false);
    const [res, setRes] = useState([])
    const [snackbarOpen, setSnackbarOpen] = React.useState(snackbarData);
    const [names, setName] = React.useState("");
    const [company, setCompany] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("")
    const handleModal = (params) => {
        setOpen(true)
        setUserData(params)
        setName(params.name)
        setCompany(params.company)
        setAddress(params.address)
        setEmail(params.email)
        setPhone(params.phonenumber)
    }
    const snackbarStart = (severity, message, st = "", sc = "") => {
        setSnackbarOpen({
            open: true,
            message: message,
            severity: severity,
            statusCode: `${st} ${sc}`
        })
    };
    const columns = [

        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            editable: true,
        },
        {
            field: 'company',
            headerName: 'Company',
            width: 150,
            editable: true,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 210,
            editable: true,
        },
        {
            field: 'phonenumber',
            headerName: 'Phone Number',
            width: 210,
            editable: true,
        },
        {
            field: 'address',
            headerName: 'Address',
            width: 210,
            editable: true,
        },


    ];
    //FETCH DATA FROM API
    const fetchData = () => {
        clients.findAll().then((res) => {
            setRes(res.data)
        }).catch(err => { console.log(err) })
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div data-testid="clients-component" style={{ height: 400, width: '100%' }}>
            {/* SNACK BAR  */}
            <DisplaySnackbar
                open={snackbarOpen.open}
                closeSnackbar={() => { setSnackbarOpen({ open: false, severity: "", message: "" }) }}
                message={snackbarOpen.message}
                severity={snackbarOpen.severity}
                statusCode={snackbarData.statusCode}
            />
            {/* ADD Data*/}
            <AddData
                snackbarStart={snackbarStart}
                fetchData={fetchData}
                setOpen={setOpen}
                setRes={setRes}
                res={res}
            />
            {/* DIAOLOG  */}
            <DialogData
                names={names}
                setName={setName}
                company={company}
                setCompany={setCompany}
                phone={phone}
                setPhone={setPhone}
                address={address}
                setAddress={setAddress}
                email={email}
                setEmail={setEmail}
                snackbarStart={snackbarStart}
                fetchData={fetchData}
                open={open}
                setOpen={setOpen}
                userData={userData}

            />
            {/* DATA GRID FOR DATA */}
            <Card>
                <div style={{ height: 780, width: '100%' }}>
                    <DataGrid
                        rows={res}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableSelectionOnClick
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'startdate', sort: 'desc' }],
                            },
                        }}
                        onRowClick={(params) => handleModal(params.row)}
                        sx={{
                            '& .MuiDataGrid-cell:hover': {
                                cursor: "pointer"
                            }
                        }}
                    />
                </div>
            </Card>
        </div>
    );
};

export default Clients;