import { leaveTypesObj } from "../../../../shared/global-constants";
import { calculateDaysBtwTwoDates } from "../../../../shared/utils";
import { toCapitalCase } from "../../../../shared/utils";

export const getBasicSalary = (salary) => {
  return (salary * 50) / 100;
};

export const getHRASalary = (salary) => {
  return (salary * 40) / 100;
};

export const getOtherAllowances = (salary) => {
  return (salary * 30) / 100;
};

export const getGrossSalary = (basic, hra, other, unpaidLeaves) => {
  return Math.round((basic + hra + other - unpaidLeaves).toFixed(2));
};

export const getPerDaySalary = (totalSalary, totalDays) => {
  return totalSalary / totalDays;
};

export const getTotalDaysInMonth = (dateObj) => {
  const totalDays = new Date(
    dateObj.getFullYear(),
    dateObj.getMonth() + 1,
    0
  ).getDate();
  return totalDays;
};

export const getTotalDays = (dateObj) => {
  const totalDays = new Date(
    dateObj.getFullYear(),
    dateObj.getMonth() + 1,
    0
  ).getDate();
  return totalDays;
};

var a = [
  "",
  "one ",
  "two ",
  "three ",
  "four ",
  "five ",
  "six ",
  "seven ",
  "eight ",
  "nine ",
  "ten ",
  "eleven ",
  "twelve ",
  "thirteen ",
  "fourteen ",
  "fifteen ",
  "sixteen ",
  "seventeen ",
  "eighteen ",
  "nineteen ",
];
var b = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

export function inWords(num) {
  if ((num = num.toString()).length > 9) return "overflow";
  const n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore " +
      "only "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh " +
      "only "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand " +
      "only "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred " +
      "only "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
      (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
      "only "
      : "";
  return toCapitalCase(str);
}

export const getCurrentMonthLeavesAndTotalDays = (selectedDate, leaves) => {
  const totalDays = {
    halfdays: 0,
    fulldays: 0,
  };

  const filteredLeaves = leaves.filter((leave) => {
    const fromDate = new Date(leave.fromdate);
    const toDate = new Date(leave.todate);
    const selectedMonth = new Date(selectedDate).getMonth();
    const selectedYear = new Date(selectedDate).getFullYear();
    if (leave.status === "approved") {
      if (
        fromDate.getFullYear() === selectedYear &&
        toDate.getFullYear() === selectedYear
      ) {
        if (
          fromDate.getMonth() === selectedMonth &&
          toDate.getMonth() === selectedMonth
        ) {
          const days = calculateDaysBtwTwoDates(leave.fromdate, leave.todate) + 1;
          if (leave.type !== leaveTypesObj.sick && leave.type !== leaveTypesObj.paid) {
            if (leave.halfday === true) {
              totalDays.halfdays += days;
            } else {
              totalDays.fulldays += days;
            }
          }
          return leave;
        } else if (
          fromDate.getMonth() === selectedMonth &&
          toDate.getMonth() !== selectedMonth
        ) {
          const newToDate = new Date(
            new Date(selectedDate).getFullYear(),
            new Date(selectedDate).getMonth() + 1,
            0
          );

          const days = calculateDaysBtwTwoDates(leave.fromdate, newToDate) + 1;

          if (leave.type !== leaveTypesObj.sick && leave.type !== leaveTypesObj.paid) {
            if (leave.halfday === true) {
              totalDays.halfdays += days;
            } else {
              totalDays.fulldays += days;
            }
          }
          leave.currentMonthLeaves = days;
          return leave;
        } else if (
          fromDate.getMonth() !== selectedMonth &&
          toDate.getMonth() === selectedMonth
        ) {
          const newFromDate = new Date(
            new Date(selectedDate).getFullYear(),
            new Date(selectedDate).getMonth(),
            1
          );
          const days = calculateDaysBtwTwoDates(newFromDate, leave.todate) + 1;
          if (leave.type !== "sick" && leave.type !== "paid") {
            if (leave.halfday === true) {
              totalDays.halfdays += days;
            } else {
              totalDays.fulldays += days;
            }
          }
          leave.currentMonthLeaves = days;
          return leave;
        }
      } else if (
        fromDate.getFullYear() === selectedYear &&
        toDate.getFullYear() !== selectedYear
      ) {
        const newToDate = new Date(
          new Date(selectedDate).getFullYear(),
          new Date(selectedDate).getMonth() + 1,
          0
        );
        const days = calculateDaysBtwTwoDates(leave.fromdate, newToDate) + 1;
        if (leave.type !== leaveTypesObj.sick && leave.type !== leaveTypesObj.paid) {
          if (leave.halfday === true) {
            totalDays.halfdays += days;
          } else {
            totalDays.fulldays += days;
          }
        }
        leave.currentMonthLeaves = days;
        return leave;
      } else if (
        fromDate.getFullYear() !== selectedYear &&
        toDate.getFullYear() === selectedYear
      ) {
        const newFromDate = new Date(
          new Date(selectedDate).getFullYear(),
          new Date(selectedDate).getMonth(),
          1
        );
        const days = calculateDaysBtwTwoDates(newFromDate, leave.todate) + 1;
        if (leave.type !== leaveTypesObj.sick && leave.type !== leaveTypesObj.paid) {
          if (leave.halfday === true) {
            totalDays.halfdays += days;
          } else {
            totalDays.fulldays += days;
          }
        }
        leave.currentMonthLeaves = days;
        return leave;
      }
    }
  });
  return { filteredLeaves, totalDays };
};

// here we are calculating the total sick, paid, unpaid and maternal leaves used in the current month
export const calculateLeaveTotals = (selectedDate, leaves) => {
  // filter leaves based on the selected month and year
  const { filteredLeaves, totalDays } = getCurrentMonthLeavesAndTotalDays(
    selectedDate,
    leaves
  );

  let sick = 0;
  let paid = 0;
  let unpaid = 0;
  let maternal = 0;

  filteredLeaves.forEach(async (leave) => {
    switch (leave.type) {
      case leaveTypesObj.sick:
        if (leave.currentMonthLeaves) {
          sick += leave.currentMonthLeaves;
        } else {
          sick += calculateDaysBtwTwoDates(leave.fromdate, leave.todate) + 1;
        }
        break;
      case leaveTypesObj.paid:
        if (leave.currentMonthLeaves) {
          paid += leave.currentMonthLeaves;
        } else {
          paid += calculateDaysBtwTwoDates(leave.fromdate, leave.todate) + 1;
        }
        break;
      case leaveTypesObj.unpaid:
        if (leave.currentMonthLeaves) {
          unpaid += leave.currentMonthLeaves;
        } else {
          unpaid += calculateDaysBtwTwoDates(leave.fromdate, leave.todate) + 1;
        }
        break;
      case leaveTypesObj.maternal:
        if (leave.currentMonthLeaves) {
          maternal += leave.currentMonthLeaves;
        } else {
          maternal +=
            calculateDaysBtwTwoDates(leave.fromdate, leave.todate) + 1;
        }
        break;
      default:
        break;
    }
  });

  console.log({
    sick,
    paid,
    unpaid,
    maternal,
    halfdays: totalDays.halfdays,
    fulldays: totalDays.fulldays,
  })
  return {
    sick,
    paid,
    unpaid,
    maternal,
    halfdays: totalDays.halfdays,
    fulldays: totalDays.fulldays,
  };
};

export const handleSalaryCalculation = (totalSalary, dateObj, leaves) => {
  const totalDaysInSelectedMonth = getTotalDaysInMonth(dateObj);

  const totalLeaves = calculateLeaveTotals(dateObj, leaves);

  const basicSalary = getBasicSalary(totalSalary);
  const hraSalary = getHRASalary(basicSalary);
  const otherAllowances = getOtherAllowances(totalSalary);

  const unpaidLeavesAmount = Math.round(
    getPerDaySalary(totalSalary, totalDaysInSelectedMonth) *
    (totalLeaves.fulldays + totalLeaves.halfdays / 2)
  );
  const grossSalary = getGrossSalary(
    basicSalary,
    hraSalary,
    otherAllowances,
    unpaidLeavesAmount
  );

  return {
    basic: basicSalary.toFixed(2),
    hra: hraSalary.toFixed(2),
    other: otherAllowances.toFixed(2),
    gross: grossSalary.toFixed(2),
    unpaid: unpaidLeavesAmount.toFixed(2),
  };
};
