import React from "react";
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from "@mui/material";

const PresentMembers = (props) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    return (
        <>
            <Card onClick={() => setDialogOpen(true)} sx={{ width: "180px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", alignSelf: "stretch" }}>
                <Typography variant="body2" sx={{ textAlign: "center", padding: "5px" }}>
                    Members Present
                </Typography>
                <Typography variant="h4" sx={{ textAlign: "center" }}>
                    {props.employeesData["length"]}
                </Typography>
            </Card>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="xs">
                <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>

                    Employees Present Today

                    <div id="icons" style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", justifyContent: "space-between" }}>
                        <CloseIcon
                            onClick={() => setDialogOpen(false)}
                            sx={{ margin: "0px 5px", cursor: "pointer" }}
                        />
                    </div>
                </DialogTitle>

                <DialogContent>
                    {
                        (props.employeesData.length > 0) && props.employeesData.result.map(
                            data =>  <Typography key={data._id} variant="body1">{data.users_permissions_user.username}</Typography>
                        )
                    }
                </DialogContent>
            </Dialog>
        </>
    );
};
export default PresentMembers;