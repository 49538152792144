import React from 'react'
import { Fab } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
const AddPermission = (props) => {
    return (
        <div data-testid="addpermission-component">
            {props.access && props.access.filter(data => {
                return data.type === props.permission
            }).map((data, index) => (
                data.type && data.enabled ?
                    <div
                        onClick={props.handler}
                        key={index}
                        style={{
                            position: "fixed",
                            zIndex: "999",
                            bottom: "1%",
                            right: "1%"
                        }}>
                        <Fab color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </div>
                    :
                    <div
                        key={index}
                        style={{
                            position: "fixed",
                            zIndex: "999",
                            bottom: "1%",
                            right: "1%"
                        }}>
                        <Fab disabled >
                            <AddIcon />
                        </Fab>
                    </div>
            ))}
        </div>
    )
}

export default AddPermission